import React from "react"
import BoardWrapper from "./components/Board"
import SettingsProvider from "./components/context/SettingsProvider"
import Settings from "./components/Settings"

const App = () => {
    return (
        <SettingsProvider>
            <Settings />
            <BoardWrapper />
        </SettingsProvider>
    )
}

export default App
