import { MODES } from "../components/context/SettingsProvider"

export const hardcoreMode = (state, keycode) => {
    switch (keycode) {
        case 38:
            if (state.direction !== "UP") {
                return { direction: "DOWN" }
            }
            break
        case 40:
            if (state.direction !== "DOWN") {
                return { direction: "UP" }
            }
            break
        case 37:
            if (state.direction !== "LEFT") {
                return { direction: "RIGHT" }
            }
            break
        case 39:
            if (state.direction !== "RIGHT") {
                return { direction: "LEFT" }
            }
            break
        default:
            break
    }
}

export const defaultMode = (state, keycode) => {
    switch (keycode) {
        case 38:
            if (state.direction !== "DOWN") {
                return { direction: "UP" }
            }
            break
        case 40:
            if (state.direction !== "UP") {
                return { direction: "DOWN" }
            }
            break
        case 37:
            if (state.direction !== "RIGHT") {
                return { direction: "LEFT" }
            }
            break
        case 39:
            if (state.direction !== "LEFT") {
                return { direction: "RIGHT" }
            }
            break
        default:
            return null
    }
}

export const rotateMode = (state, keycode) => {
    const moduloAngle = state.angle % 360

    switch (keycode) {
        //left
        case 37:
            if (moduloAngle === 0 || moduloAngle === -0) {
                return { direction: "UP", angle: state.angle + 90 }
            } else if (moduloAngle === 90 || moduloAngle === -270) {
                console.log("ici non")
                return { direction: "RIGHT", angle: state.angle + 90 }
            } else if (moduloAngle === 180 || moduloAngle === -180) {
                return { direction: "LEFT", angle: state.angle + 90 }
            } else if (moduloAngle === 270 || moduloAngle === -90) {
                return { direction: "DOWN", angle: state.angle + 90 }
            }
            break
        //right
        case 39:
            if (moduloAngle === 0 || moduloAngle === -0) {
                return { direction: "DOWN", angle: state.angle - 90 }
            } else if (moduloAngle === -90 || moduloAngle === 270) {
                return { direction: "LEFT", angle: state.angle - 90 }
            } else if (moduloAngle === -180 || moduloAngle === 180) {
                return { direction: "UP", angle: state.angle - 90 }
            } else if (moduloAngle === -270 || moduloAngle === 90) {
                return { direction: "RIGHT", angle: state.angle - 90 }
            }

            break
        default:
            return null
    }
}

export default {
    [MODES.HARDCORE]: hardcoreMode,
    [MODES.ROTATE]: rotateMode
}
