import React from "react"
import { SettingsContext, MODES } from "./context/SettingsProvider"

const Settings = () => {
    return (
        <SettingsContext.Consumer>
            {({ mode, toggleMode }) => (
                <div
                    className={
                        mode.includes(MODES.LIGHT) ? "settings-container light" : "settings-container dark"
                    }
                >
                    <div className="row">
                        <span>Impossible Mode</span>
                        <label className="switch">
                            <input
                                type="checkbox"
                                defaultChecked={mode.includes(MODES.IMPOSSIBLE)}
                                onClick={() => toggleMode(MODES.IMPOSSIBLE)}
                            />
                            <span></span>
                        </label>
                    </div>
                    <div className="row">
                        <span>Hardcore Mode</span>
                        <label className="switch">
                            <input
                                type="checkbox"
                                defaultChecked={mode.includes(MODES.HARDCORE)}
                                onClick={() => toggleMode(MODES.HARDCORE)}
                            />
                            <span></span>
                        </label>
                    </div>
                    <div className="row">
                        <span>Rotate Mode</span>
                        <label className="switch">
                            <input
                                type="checkbox"
                                defaultChecked={mode.includes(MODES.ROTATE)}
                                onClick={() => toggleMode(MODES.ROTATE)}
                            />
                            <span></span>
                        </label>
                    </div>
                    <div className="row">
                        <span>Corner Mode</span>
                        <label className="switch">
                            <input
                                type="checkbox"
                                defaultChecked={mode.includes(MODES.CORNER)}
                                onClick={() => toggleMode(MODES.CORNER)}
                            />
                            <span></span>
                        </label>
                    </div>
                </div>
            )}
        </SettingsContext.Consumer>
    )
}

export default Settings
