import React, { Component } from "react"

export const MODES = {
    DEFAULT: "DEFAULT",
    IMPOSSIBLE: "IMPOSSIBLE",
    BOT: "BOT",
    HARDCORE: "HARDCORE",
    ROTATE: "ROTATE",
    LIGHT: "LIGHT",
    CORNER: "CORNER"
}

export default class SettingsProvider extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            mode: [],
            addMode: this.addMode,
            removeMode: this.removeMode,
            toggleMode: this.toggleMode
        }
    }

    addMode = newMode => {
        this.setState({ mode: this.state.mode.concat([newMode]) })
    }

    removeMode = mode => {
        const index = this.state.mode.indexOf(mode)
        if (index !== -1) {
            const newModes = this.state.mode.slice()
            newModes.splice(index, 1)
            this.setState({ mode: newModes })
        }
    }

    toggleMode = mode => {
        if (this.state.mode.indexOf(mode) !== -1) {
            this.removeMode(mode)
        } else {
            this.addMode(mode)
        }
    }

    render() {
        return <SettingsContext.Provider value={this.state}>{this.props.children}</SettingsContext.Provider>
    }
}

export const SettingsContext = React.createContext({
    mode: [],
    addMode: () => {},
    removeMode: () => {},
    toggleMode: () => {}
})
