import React from "react"
import youdied from "../assets/video/died.mp4"
import wasted from "../assets/video/wasted.mp4"

const Death = props => {
    let vid = null
    if (props.vid === "wasted") {
        vid = wasted
    } else {
        vid = youdied
    }

    return (
        <video
            autoPlay
            onEnded={() => {
                props.deleteVid()
                props.resume()
            }}
        >
            <source src={vid} type="video/mp4" />
        </video>
    )
}

export default Death
