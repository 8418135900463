import React, { Component } from "react"
import Snake from "./Snake"
import Food from "./Food"
import Stats from "./Stats"
import Death from "./Death"
import { SettingsContext, MODES } from "./context/SettingsProvider"
import modeControls, { defaultMode } from "../utils/modeControls"

function difference(array1, array2) {
    return array1.length > array2.length
        ? array1.filter(value => -1 === array2.indexOf(value))
        : array2.filter(value => -1 === array1.indexOf(value))
}

const impossiblemode = new Audio(require("../assets/audio/impossiblemode.mp3"))
const nice = new Audio(require("../assets/audio/nice.mp3"))

const audioEat = [
    new Audio(require("../assets/audio/eat-1.mp3")),
    new Audio(require("../assets/audio/eat-2.mp3")),
    new Audio(require("../assets/audio/eat-3.mp3"))
]

class Board extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            snakeDots: [
                [0, 0],
                [2, 0]
            ],
            food: this.randomCoordinates(),
            direction: "RIGHT",
            speed: 100,
            gamePlayed: 1,
            displayVid: false,
            pause: false,
            foodChangeColor: false,
            angle: 0
        }
        this.interval = null
    }

    componentDidMount() {
        document.onkeydown = this.onKeyDown
        this.speed(false)
    }

    componentDidUpdate(prevProps) {
        if (this.props.settings.mode !== prevProps.settings.mode) {
            const arrayDifference = difference(prevProps.settings.mode, this.props.settings.mode)
            const modeDifference = arrayDifference.length > 0 ? arrayDifference[0] : null

            if (this.props.settings.mode.includes(MODES.IMPOSSIBLE)) {
                this.setState(
                    {
                        speed: 20
                    },
                    () => {
                        this.speed(true)
                    }
                )

                impossiblemode.play()
                impossiblemode.volume = 0.3
            } else if (![MODES.LIGHT].includes(modeDifference)) {
                this.setState(
                    {
                        speed: 100
                    },
                    () => {
                        this.speed(false)
                    }
                )
                impossiblemode.pause()
                impossiblemode.currentTime = 0
            }
        }

        if (!this.state.pause) {
            this.outOfBorder()
            this.collapsed()
            this.eatFood()
        }
    }

    randomCoordinates = () => {
        let x, y

        if (this.props.settings.mode.includes(MODES.CORNER)) {
            const r1 = Math.random() > 0.5 ? 0 : 98
            const r2 = Math.floor((Math.random() * (98 - 1 + 1)) / 2) * 2

            if (Math.random() > 0.5) {
                y = r1
                x = r2
            } else {
                y = r2
                x = r1
            }

            return [x, y]
        } else {
            let min = 1
            let max = 98
            x = Math.floor((Math.random() * (max - min + 1) + min) / 2) * 2
            y = Math.floor((Math.random() * (max - min + 1) + min) / 2) * 2

            return [x, y]
        }
    }

    speed = bool => {
        clearInterval(this.interval)
        if (!this.state.pause) {
            if (bool) {
                this.interval = setInterval(this.moveSnake, this.state.speed)
            } else {
                this.interval = setInterval(this.moveSnake, this.state.speed)
            }
        }
    }

    onKeyDown = e => {
        e = e || window.event
        const currentModes = this.props.settings.mode

        const controlMethod = currentModes.reduce(
            (acc, cur) => (modeControls.hasOwnProperty(cur) ? modeControls[cur] : acc),
            defaultMode
        )

        const newState = controlMethod(this.state, e.keyCode)

        if (newState !== null) {
            this.setState(newState)
        }
    }

    pauseGame = () => {
        impossiblemode.pause()
        impossiblemode.currentTime = 0
        clearInterval(this.interval)
        this.setState({ pause: true })
    }

    resumeGame = () => {
        this.setState({ pause: false })
        this.interval = setInterval(this.moveSnake, this.state.speed)
    }

    moveSnake = () => {
        let dots = [...this.state.snakeDots]
        let head = dots[dots.length - 1]

        switch (this.state.direction) {
            case "RIGHT":
                head = [head[0] + 2, head[1]]
                break
            case "LEFT":
                head = [head[0] - 2, head[1]]
                break
            case "DOWN":
                head = [head[0], head[1] + 2]
                break
            case "UP":
                head = [head[0], head[1] - 2]
                break
            default:
                break
        }
        dots.push(head)
        dots.shift()
        this.setState({
            snakeDots: dots
        })
    }

    collapsed = () => {
        let snake = [...this.state.snakeDots]
        let head = snake[snake.length - 1]
        snake.pop()
        snake.forEach(dot => {
            if (head[0] === dot[0] && head[1] === dot[1]) {
                this.gameOver()
            }
        })
    }

    eatFood = () => {
        let head = this.state.snakeDots[this.state.snakeDots.length - 1]
        let food = this.state.food

        if (head[0] === food[0] && head[1] === food[1]) {
            this.setState({
                food: this.randomCoordinates()
            })

            if (Math.random() > 0.5) {
                this.props.settings.toggleMode(MODES.LIGHT)
            }

            audioEat[Math.floor(Math.random() * audioEat.length)].play()

            if (this.state.snakeDots.length % 5 === 0) {
                setTimeout(() => {
                    nice.play()
                }, 500)
            }

            this.enlargeSnake()
            if (!this.props.settings.mode.includes(MODES.IMPOSSIBLE)) {
                this.increaseSpeed()
            }
        }
    }

    enlargeSnake = () => {
        let newSnake = [...this.state.snakeDots]
        newSnake.unshift([])
        this.setState({
            snakeDots: newSnake
        })
    }

    increaseSpeed = () => {
        if (this.state.speed > 40) {
            this.setState(
                {
                    speed: this.state.speed - 2
                },
                () => this.speed()
            )
        }
    }

    outOfBorder = () => {
        let head = this.state.snakeDots[this.state.snakeDots.length - 1]
        if (head[0] >= 100 || head[1] >= 100 || head[0] < 0 || head[1] < 0) {
            this.gameOver()
        }
    }

    gameOver() {
        if (this.state.gamePlayed === 49) {
            this.setState({
                displayVid: true
            })
            this.pauseGame()
        }

        let speed = null
        if (this.props.settings.mode.includes(MODES.IMPOSSIBLE)) {
            speed = 20
        } else {
            speed = 100
        }
        clearInterval(this.interval)
        this.interval = setInterval(this.moveSnake, speed)
        this.setState({
            snakeDots: [
                [0, 0],
                [2, 0]
            ],
            food: this.randomCoordinates(),
            direction: "RIGHT",
            speed: speed,
            gamePlayed: this.state.gamePlayed + 1,
            angle: 0
        })
    }

    deleteVid = () => {
        this.setState({
            displayVid: false,
            snakeDots: [
                [0, 0],
                [2, 0]
            ],
            food: this.randomCoordinates(),
            direction: "RIGHT",
            speed: 100,
            gamePlayed: this.state.gamePlayed + 1,
            angle: 0
        })
    }

    render() {
        return (
            <div
                className={
                    this.props.settings.mode.includes(MODES.LIGHT)
                        ? "board-container light"
                        : "board-container dark"
                }
            >
                <Stats
                    score={this.state.snakeDots.length}
                    time
                    games={this.state.gamePlayed}
                    speed={this.state.speed}
                />

                <div className="board" style={{ transform: `rotate(${this.state.angle}deg)` }}>
                    <Snake snakeDots={this.state.snakeDots} />
                    <Food dot={this.state.food} changeColor={this.state.foodChangeColor} />
                </div>
                {this.state.displayVid ? (
                    <Death vid={"wasted"} deleteVid={this.deleteVid} resume={this.resumeGame} />
                ) : null}
            </div>
        )
    }
}

class BoardWrapper extends Component {
    render() {
        return (
            <SettingsContext.Consumer>{settings => <Board settings={settings} />}</SettingsContext.Consumer>
        )
    }
}

export default BoardWrapper
