import React from "react"

const Stats = props => {
    return (
        <div className="stats-container">
            <span>Score: {props.score}</span>
            <span>Speed: {props.speed}</span>
            <span>Game: {props.games}</span>
        </div>
    )
}

export default Stats
